<template>
  <div class="flex justify-center">
    <div class="w-[378px]">
      <p class="text-gray-900 text-3xl font-semibold text-center">
        It looks like we don’t have any data yet!
      </p>
      <p class="text-2xl text-gray-900 font-medium text-center mt-8 mb-14">
        We don’t have enough information to display in your dashboard. For more
        info <span class="text-[#10B981]">contact us.</span>
      </p>
      <img
        src="@/assets/img/empty-state.svg"
        alt="empty"
        class="mx-auto pt-5"
      />
    </div>
  </div>
</template>
